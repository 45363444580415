import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Stat from "./Stat";
import ActionAreas from "./ActionAreas";
import { GraphicsContext } from "../../contexts/GraphicsContext";
import { DataContext } from "../../contexts/DataContext";
import { APIContext } from "../../contexts/APIContext";
import { UIContext } from "../../contexts/UIContext";
import useLocalStorage from "../../Hooks/useLocalStorage";
import moment from "moment";
const defaultStats = {
  possessionPercentage: 0,
  touches: 0,
  interception: 0,
  lostCorners: 0,
  fkFoulWon: 0,
  totalPass: 0,
  accuratePass: 0,
  passAccuracy: 0,
  totalPassInclCross: 0,
  totalTackle: 0,
  wonCorners: 0,
  cornerTaken: 0,
  totalCross: 0,
  totalThrows: 0,
  fkFoulLost: 0,
  totalYellowCard: 0,
  totalYellowCardOnPitch: 0,
  totalScoringAtt: 0,
  shotOffTarget: 0,
  shotAccuracy: 0,
  attRfTotal: 0,
  attIbox: 0,
  blockedScoringAtt: 0,
  attHdTotal: 0,
  wonTackle: 0,
  attLfTotal: 0,
  totalOffside: 0,
  ontargetScoringAtt: 0,
  goalKicks: 0,
  attFreekickTotal: 0,
  secondYellow: 0,
  totalRedCard: 0,
  secondYellowOnPitch: 0,
  totalRedCardOnPitch: 0,
  penaltyWon: 0,
  goals: 0,
  attRfGoal: 0,
  penaltyTaken: 0,
  attIboxGoal: 0,
  assistPenaltyWon: 0,
};
const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
const Row = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 1em;
  font-weight: 100;
  align-items: center;
`;
const Button = styled.div`
  padding: 0.5em;
  min-width: 50px;
  display: flex;
  background-color: ${(props) =>
    props.selected ? "#db0a41" : props.green ? "#0adb93" : "#202731"};
  color: ${(props) => (props.green ? "#000" : "#fff")};
  justify-content: center;
  align-items: center;
  height: 55%;
  border-radius: 4px;
  margin: 0.5em;
  box-sizing: border-box;
  :hover {
    background-color: ${(props) => (props.green ? "#0adb93" : "#db0a41")};
    cursor: pointer;
  }
`;

let sponsors = [
  { time: 15, period: 1, animated: false },
  { time: 30, period: 1, animated: false },
  { time: 50, period: 2, animated: false },
  { time: 65, period: 2, animated: false },
];
export default function TeamStats({ game }) {
  const { setView, selectedTeam, setGoal } = useContext(UIContext);
  const { animateGraphic, animateOffGraphic, previewGraphic, updateGraphic } =
    useContext(GraphicsContext);
  const { updateGame } = useContext(APIContext);
  const [showAcc, setShowAcc] = useState(false);
  const [autoClockSponsor, setAutoClockSponsor] = useLocalStorage(
    "auto_clock_sponsor",
    false
  );
  const [nextSponsor, setNextSponsor] = useState();

  function getScore(game) {
    let goal_points = 3;

    let home_score =
      (game?.home_scorers?.filter((g) => g.type === "goal").length *
        goal_points || 0) +
      (game?.home_scorers?.filter((g) => g.type === "point").length || 0);

    let away_score =
      (game?.away_scorers?.filter((g) => g.type === "goal").length *
        goal_points || 0) +
      (game?.away_scorers?.filter((g) => g.type === "point").length || 0);

    return {
      home_score,
      away_score,
    };
  }

  useEffect(() => {
    if (game && autoClockSponsor) {
      let period = [...(game?.live_data?.periods || [])].sort(
        (a, b) => b.number - a.number
      )[0];

      if (period) {
        if (period?.kick_off === null) {
          sponsors = [
            { time: 15, period: 1, animated: false },
            { time: 30, period: 1, animated: false },
            { time: 50, period: 2, animated: false },
            { time: 65, period: 2, animated: false },
          ];
        }
        getNextClockSponsor(period.kick_off, period);
      }
      let interval = setInterval(() => {
        if (period) {
          getNextClockSponsor(period.kick_off, period);
        }
      }, 500);
      return () => {
        clearInterval(interval);
      };
    } else {
      getNextClockSponsor(0);
    }
  }, [game, autoClockSponsor]);

  function getNextClockSponsor(koTime, period) {
    if (koTime) {
      let pauseTime = 0;

      period.pause &&
        period.pause.forEach((pause) => {
          if (pause.end) {
            pauseTime += pause.end - pause.start;
          }
        });
      let latestPause = period.pause && period.pause[period.pause.length - 1];
      if (latestPause && latestPause.end) {
        latestPause = null;
      }
      let time = moment.duration(
        ((latestPause && latestPause.start) || Date.now()) - koTime - pauseTime
      );

      let minutes = time.minutes() + time.hours() * 60;
      if (period.number === 2) {
        minutes += game?.config?.half_duration || 35;
      } else if (period.number === 3) {
        minutes += (game?.config?.half_duration || 35) * 2;
      } else if (period.number === 4) {
        minutes += 105;
      }

      let sponsor_index = sponsors?.findIndex(
        (s) =>
          s.period === period.number &&
          s.time >= minutes &&
          s.animated === false
      );

      let seconds =
        sponsors[sponsor_index]?.time * 60 - (minutes * 60 + time.seconds());
      if (seconds <= 0 && seconds > -5 && sponsor_index > -1) {
        sponsors[sponsor_index].animated = true;
        animateGraphic("ClockSponsor");
      } else if (sponsor_index > -1) {
        const formatted = moment(seconds * 1000).format("mm:ss");
        setNextSponsor(formatted);
      } else {
        setNextSponsor("");
      }
    }
  }

  return (
    <Main>
      <Row>
        <Stat
          game={game}
          category={"Total Points"}
          matchStats={[]}
          stat={{
            home: getScore(game)?.home_score || 0,
            away: getScore(game)?.away_score || 0,
          }}
          previewGraphic={(name, options) => {
            let newShow = !showAcc;
            setShowAcc(newShow);
            updateGraphic("CLOCK", {
              type: "ACC_SCORE",
              show: newShow,
            });
          }}
        />
      </Row>
      <Row>
        <Stat
          game={game}
          category={"Goals"}
          matchStats={[]}
          stat={{
            home:
              game?.home_scorers?.filter((g) => g.type === "goal").length || 0,
            away:
              game?.away_scorers?.filter((g) => g.type === "goal").length || 0,
          }}
          updateHomeStat={({ e }) => {
            debugger;
            switch (e.button) {
              case 0:
                let period =
                  game.live_data &&
                  game.live_data?.periods &&
                  game.live_data?.periods.sort(
                    (a, b) => b.number - a.number
                  )[0];
                let ko_time = period?.kick_off;
                let time = ko_time ? Date.now() - ko_time : 0;
                if (period.number === 1) {
                  if (time > 1000 * 60 * 40) {
                    time = 1000 * 60 * 40;
                  }
                }
                if (period.number === 2) {
                  //time += 1000 * 60 * 40;
                  if (time > 1000 * 60 * 80) {
                    time = 1000 * 60 * 80;
                  }
                }

                let _id = Date.now();
                setGoal({
                  team: game.home_team,
                  time: time + 1000 * 60,
                  _id: _id,
                });
                updateGame({
                  id: game._id,
                  home_scorers: [
                    ...game.home_scorers,
                    { time: time, _id, type: "goal" },
                  ],
                });
                break;
              case 2:
                if (game?.home_scorers?.length - 1 >= 0) {
                  let newScorers = [...game.home_scorers];

                  let latestGoals = newScorers
                    .filter((g) => g.type === "goal")
                    .sort((a, b) => {
                      return b.time - a.time;
                    });
                  if (latestGoals.length > 0) {
                    newScorers = game.home_scorers.filter(
                      (g) => g !== latestGoals[0]
                    );
                    updateGame({
                      id: game._id,
                      home_scorers: newScorers,
                    });
                  }
                }
                break;
              default:
                break;
            }
          }}
          updateAwayStat={({ e }) => {
            switch (e.button) {
              case 0:
                let period =
                  game.live_data &&
                  game.live_data?.periods &&
                  game.live_data?.periods.sort(
                    (a, b) => b.number - a.number
                  )[0];
                let ko_time = period?.kick_off;
                let time = ko_time ? Date.now() - ko_time : 0;
                if (period.number === 1) {
                  if (time > 1000 * 60 * 40) {
                    time = 1000 * 60 * 40;
                  }
                }
                if (period.number === 2) {
                  //time += 1000 * 60 * 40;
                  if (time > 1000 * 60 * 80) {
                    time = 1000 * 60 * 80;
                  }
                }

                let _id = Date.now();
                setGoal({
                  team: game.away_team,
                  time: time + 1000 * 60,
                  _id: _id,
                });
                updateGame({
                  id: game._id,
                  away_scorers: [
                    ...game.away_scorers,
                    { time: time, _id, type: "goal" },
                  ],
                });
                break;
              case 2:
                if (game?.away_scorers?.length - 1 >= 0) {
                  let newScorers = [...game.away_scorers];

                  let latestGoals = newScorers
                    .filter((g) => g.type === "goal")
                    .sort((a, b) => {
                      return b.time - a.time;
                    });
                  if (latestGoals.length > 0) {
                    newScorers = game.away_scorers.filter(
                      (g) => g !== latestGoals[0]
                    );
                    updateGame({
                      id: game._id,
                      away_scorers: newScorers,
                    });
                  }
                }
                break;
              default:
                break;
            }
          }}
          previewGraphic={(name, options) => {
            previewGraphic(name, {
              ...options,
            });
          }}
        />
      </Row>
      <Row>
        <Stat
          game={game}
          category={"Points"}
          matchStats={[]}
          // updateStat={updateStat}
          previewGraphic={(name, options) => {
            previewGraphic(name, {
              ...options,
            });
          }}
          stat={{
            home:
              game?.home_scorers?.filter((g) => g.type === "point").length || 0,
            away:
              game?.away_scorers?.filter((g) => g.type === "point").length || 0,
          }}
          updateHomeStat={({ e }) => {
            switch (e.button) {
              case 0:
                let period =
                  game.live_data &&
                  game.live_data?.periods &&
                  game.live_data?.periods.sort(
                    (a, b) => b.number - a.number
                  )[0];
                let ko_time = period?.kick_off;
                let time = ko_time ? Date.now() - ko_time : 0;
                if (period.number === 1) {
                  if (time > 1000 * 60 * 40) {
                    time = 1000 * 60 * 40;
                  }
                }
                if (period.number === 2) {
                  time += 1000 * 60 * 40;
                  if (time > 1000 * 60 * 80) {
                    time = 1000 * 60 * 80;
                  }
                }

                let _id = Date.now();
                setGoal({
                  team: game.home_team,
                  time: time + 1000 * 60,
                  _id: _id,
                });
                updateGame({
                  id: game._id,
                  home_scorers: [
                    ...game.home_scorers,
                    { time: time, _id, type: "point" },
                  ],
                });
                break;
              case 2:
                if (game?.home_scorers?.length - 1 >= 0) {
                  let newScorers = [...game.home_scorers];

                  let latestGoals = newScorers
                    .filter((g) => g.type === "point")
                    .sort((a, b) => {
                      return b.time - a.time;
                    });
                  if (latestGoals.length > 0) {
                    newScorers = game.home_scorers.filter(
                      (g) => g !== latestGoals[0]
                    );
                    updateGame({
                      id: game._id,
                      home_scorers: newScorers,
                    });
                  }
                }
                break;
              default:
                break;
            }
          }}
          updateAwayStat={({ e }) => {
            switch (e.button) {
              case 0:
                let period =
                  game.live_data &&
                  game.live_data?.periods &&
                  game.live_data?.periods.sort(
                    (a, b) => b.number - a.number
                  )[0];
                let ko_time = period?.kick_off;
                let time = ko_time ? Date.now() - ko_time : 0;
                if (period.number === 1) {
                  if (time > 1000 * 60 * 40) {
                    time = 1000 * 60 * 40;
                  }
                }
                if (period.number === 2) {
                  time += 1000 * 60 * 40;
                  if (time > 1000 * 60 * 80) {
                    time = 1000 * 60 * 80;
                  }
                }

                let _id = Date.now();
                setGoal({
                  team: game.away_team,
                  time: time + 1000 * 60,
                  _id: _id,
                });
                updateGame({
                  id: game._id,
                  away_scorers: [
                    ...game.away_scorers,
                    { time: time, _id, type: "point" },
                  ],
                });
                break;
              case 2:
                if (game?.away_scorers?.length - 1 >= 0) {
                  let newScorers = [...game.away_scorers];

                  let latestGoals = newScorers
                    .filter((g) => g.type === "point")
                    .sort((a, b) => {
                      return b.time - a.time;
                    });
                  if (latestGoals.length > 0) {
                    newScorers = game.away_scorers.filter(
                      (g) => g !== latestGoals[0]
                    );
                    updateGame({
                      id: game._id,
                      away_scorers: newScorers,
                    });
                  }
                }
                break;
              default:
                break;
            }
          }}
        />
      </Row>
      <Row>
        Clock Sponsor
        <Button
          onClick={() => {
            animateGraphic("ClockSponsor");
          }}
        >
          IN
        </Button>
        <Button
          onClick={() => {
            animateOffGraphic("ClockSponsor");
          }}
        >
          OUT
        </Button>
      </Row>
      <Row>
        Auto Clock Sponsor
        <Button
          green={autoClockSponsor}
          onClick={() => {
            setAutoClockSponsor(true);
          }}
        >
          ON
        </Button>
        <Button
          green={!autoClockSponsor}
          onClick={() => {
            setAutoClockSponsor(false);
          }}
        >
          OFF
        </Button>
      </Row>
      {autoClockSponsor && nextSponsor !== "" && (
        <Row>Next sponsor animating in: {nextSponsor}</Row>
      )}
    </Main>
  );
}
