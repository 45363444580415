import React, { useContext } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { APIContext } from "../../contexts/APIContext";
import { DataContext } from "../../contexts/DataContext";
import { GraphicsContext } from "../../contexts/GraphicsContext";
import { UIContext, VIEW_CONSTS } from "../../contexts/UIContext";

const Main = styled.div`
  display: flex;
  flex-direction: column;
`;

const ActionPanel = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 1em;
  box-sizing: border-box;
  height: 100px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  background-color: #181d25;
`;

const Button = styled.div`
  display: flex;
  background-color: #202731;

  width: 90%;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 1em;
  justify-content: center;
  align-items: center;
  max-width: 200px;
  cursor: pointer;
  :hover {
    background-color: #db0a41;
  }
`;

export default function ClockEdit({ game }) {
  const { updateGame } = useContext(APIContext);
  const { updateGame: updateGameData, update } = useContext(DataContext);
  const { updateGraphic } = useContext(GraphicsContext);
  const history = useHistory();
  const { view, setView } = useContext(UIContext);
  let period = [...(game?.live_data?.periods || [])].sort(
    (a, b) => b.number - a.number
  )[0];

  return (
    <Main>
      <ActionPanel>
        <Button
          onClick={() => {
            let newData = { ...JSON.parse(JSON.stringify(game)) };
            newData.live_data.periods = [{ kick_off: null, number: 1 }];
            updateGraphic("CLOCK", {
              game: game,
              period: { kick_off: null, number: 1 },
            });
            updateGame({ id: game._id, live_data: newData.live_data });
          }}
        >
          Reset Clock 00:00
        </Button>
        <Button
          onClick={() => {
            let newData = { ...JSON.parse(JSON.stringify(game)) };
            newData.live_data.periods = [
              { kick_off: null, number: 1 },
              { number: 2, kick_off: null },
            ];
            updateGraphic("CLOCK", {
              game: game,
              period: { number: 2, kick_off: null },
            });
            updateGame({ id: game._id, live_data: newData.live_data });
          }}
        >
          Reset Clock {game?.config?.half_duration || "35"}:00
        </Button>
        <Button
          onClick={() => {
            let newData = { ...JSON.parse(JSON.stringify(game)) };
            newData.live_data.periods = [
              { kick_off: null, number: 1 },
              { number: 2, kick_off: null },
              { number: 3, kick_off: null },
            ];
            updateGraphic("CLOCK", {
              game: game,
              period: { number: 3, kick_off: null },
            });
            updateGame({ id: game._id, live_data: newData.live_data });
          }}
        >
          Reset Clock ET1
        </Button>
        <Button
          onClick={() => {
            let newData = { ...JSON.parse(JSON.stringify(game)) };
            newData.live_data.periods = [
              { kick_off: null, number: 1 },
              { number: 2, kick_off: null },
              { number: 3, kick_off: null },
              { number: 4, kick_off: null },
            ];
            updateGraphic("CLOCK", {
              game: game,
              period: { number: 4, kick_off: null },
            });
            updateGame({ id: game._id, live_data: newData.live_data });
          }}
        >
          Reset Clock ET2
        </Button>
        {/* <Button
          onClick={() => {
            update({
              type: "update_game",
              _id: game._id,
              variable: "live_data.periods",
              data: [
                { number: 1, kick_off: null },
                { number: 2, kick_off: null },
              ],
            });
          }}
        >
          Reset Clock 80:00
        </Button> */}
        {period?.kick_off && (
          <>
            <Button
              onClick={() => {
                let newData = { ...JSON.parse(JSON.stringify(game)) };
                let index = newData.live_data.periods.findIndex(
                  (p) => p.number === period.number
                );
                newData.live_data.periods[index].kick_off += 60000;
                updateGraphic("CLOCK", {
                  game: game,
                  period: newData.live_data.periods[index],
                });
                updateGame({ id: game._id, live_data: newData.live_data });
              }}
            >
              -1 Min
            </Button>

            <Button
              onClick={() => {
                let newData = { ...JSON.parse(JSON.stringify(game)) };
                let index = newData.live_data.periods.findIndex(
                  (p) => p.number === period.number
                );
                newData.live_data.periods[index].kick_off -= 60000;
                updateGraphic("CLOCK", {
                  game: game,
                  period: newData.live_data.periods[index],
                });
                updateGame({ id: game._id, live_data: newData.live_data });
              }}
            >
              +1 Min
            </Button>

            <Button
              onClick={() => {
                let newData = { ...JSON.parse(JSON.stringify(game)) };
                let index = newData.live_data.periods.findIndex(
                  (p) => p.number === period.number
                );
                newData.live_data.periods[index].kick_off += 1000;
                updateGraphic("CLOCK", {
                  game: game,
                  period: newData.live_data.periods[index],
                });
                updateGame({ id: game._id, live_data: newData.live_data });
              }}
            >
              -1 Sec
            </Button>
            <Button
              onClick={() => {
                let newData = { ...JSON.parse(JSON.stringify(game)) };
                let index = newData.live_data.periods.findIndex(
                  (p) => p.number === period.number
                );
                newData.live_data.periods[index].kick_off -= 1000;
                updateGraphic("CLOCK", {
                  game: game,
                  period: newData.live_data.periods[index],
                });
                updateGame({ id: game._id, live_data: newData.live_data });
              }}
            >
              +1 Sec
            </Button>
          </>
        )}
      </ActionPanel>
      <ActionPanel>
        <Button
          onClick={() => {
            updateGame({
              id: game._id,
              live_data: { periods: [{ kick_off: null, number: 1 }] },
              lineup: { home: [], away: [] },
              home_scorers: [],
              away_scorers: [],
            });
          }}
        >
          RESET GAME
        </Button>
        <Button
          onClick={() => {
            updateGame({
              id: game._id,
              home_scorers: [],
              away_scorers: [],
            });
          }}
        >
          RESET GOALS
        </Button>
      </ActionPanel>
      <ActionPanel>
        <Button
          onClick={() => {
            history.push("/" + game._id + "/penalties");
          }}
        >
          Penalty Mode
        </Button>
      </ActionPanel>
    </Main>
  );
}
