import React from "react";
import { useContext, useEffect } from "react";
import styled from "styled-components";
import { Button, ScrollBar } from "../../Theme/Hyper";
import { APIContext } from "../../contexts/APIContext";
import SelectUI from "../UI/Form/SelectUI";
import { useState } from "react";
import RightSideBar from "./RightSideBar";
import useLocalStorage from "../../Hooks/useLocalStorage";
import { GraphicsContext } from "../../contexts/GraphicsContext";
const Main = styled.div`
  display: flex;
`;
const Row = styled.div`
  display: flex;
`;
const Teams = styled(ScrollBar)`
  display: flex;
  flex-direction: column;
  margin: 5px;
  max-height: 90vh;
  overflow-y: scroll;
  width: 250px;
`;
const Input = styled.input`
  background-color: #1f222b;
  border-radius: 4px;
  border: none;
  padding: 1em;
  margin-bottom: 1em;
  outline: none;
  color: #fff;
`;
const Team = styled.div`
  display: flex;
  min-height: 39px;
  background-color: ${(props) => (props.selected ? "#0adb93" : "#202731")};
  color: ${(props) => (props.selected ? "#000" : "")};
  border-bottom: 2px solid #181d25;
  font-size: 1em;
  font-weight: 100;
  align-items: center;
  padding-left: 5px;
  opacity: ${(props) => (props.fade ? "0.5" : "1")};

  :hover {
    background-color: #0adb93;
  }
`;
const Select = styled.div`
  display: flex;
  width: 200px;
  margin-right: 10px;
`;

const Group = styled.div`
  display: flex;
  background-color: #202731;
  border-radius: 4px;
  min-width: 400px;
  height: 300px;
  margin: 10px;
  flex-direction: column;
`;

const GroupTeams = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Title = styled.div`
  padding: 10px;
`;

const RightBar = styled.div`
  display: flex;
  width: 300px;
  box-shadow: -3px 1px 3px 0 rgb(0 0 0 / 42%);
  height: 100vh;
`;
const Content = styled.div`
  display: flex;
  margin-left: 1em;

  margin-top: 1em;
  box-sizing: border-box;
  flex: 1;
  flex-direction: column;
`;
function Draw() {
  const { getCompetition, getSeason } = useContext(APIContext);
  const [competitions, setCompetitions] = useState([]);
  const [seasons, setSeasons] = useState([]);
  const [showCustomTeam, setShowCustomTeam] = useState(false);
  const [selectedCompetition, setSelectedCompetition] = useLocalStorage(
    "draw_comp",
    []
  );
  const [selectedSeason, setSelectedSeason] = useLocalStorage(
    "draw_season",
    []
  );
  const [selectedSlot, setSelectedSlot] = useState();
  const [newCustomTeam, setNewCustomTeam] = useState();
  const [groups, setGroups] = useLocalStorage("draw_groups", []);

  const [customTeams, setCustomTeams] = useLocalStorage("draw_custom", []);
  const {
    previewGraphic,
    animateGraphic,
    graphicInPreview,
    animateOffGraphic,
    updateGraphic,
    clearPVW,
  } = useContext(GraphicsContext);

  useEffect(() => {
    getCompetition()?.then((data) => {
      setCompetitions([...(data || [])]);
    });
  }, []);

  useEffect(() => {
    getSeason()?.then((data) => {
      setSeasons(
        data?.filter((s) => s.competition?._id === selectedCompetition?._id)
      );
    });
  }, [selectedCompetition]);

  useEffect(() => {
    updateGraphic("Draw", { groups });
  }, [groups]);

  return (
    <Main>
      <Content>
        <Row>
          <Select>
            <SelectUI
              value={
                selectedCompetition
                  ? {
                      value: selectedCompetition,
                      label: selectedCompetition?.name,
                    }
                  : ""
              }
              options={competitions
                ?.sort((a, b) => {
                  return (a.order || 999) - (b.order || 999);
                })
                ?.map((c) => {
                  return {
                    value: c,
                    label: c?.name,
                  };
                })}
              placeholder="Select Competition"
              onChange={(comp) => {
                setSelectedCompetition(comp);
              }}
            />
          </Select>
          <Select>
            <SelectUI
              value={
                selectedSeason
                  ? {
                      value: selectedSeason,
                      label: selectedSeason?.name,
                    }
                  : ""
              }
              options={seasons
                .sort((a, b) => {
                  return (a.order || 999) - (b.order || 999);
                })
                .map((c) => {
                  return {
                    value: c,
                    label: c?.name,
                  };
                })}
              placeholder="Select Season"
              onChange={(season) => {
                setSelectedSeason(season);
              }}
            />
          </Select>
        </Row>
        <Row>
          <Teams>
            {JSON.parse(JSON.stringify(selectedSeason?.teams || []))
              ?.sort((a, b) => {
                if (a.name < b.name) {
                  return -1;
                }
                if (a.name > b.name) {
                  return 1;
                }
                return 0;
              })
              ?.slice(0, selectedSeason?.teams?.length / 2)
              ?.map((team) => {
                return (
                  <Team
                    fade={
                      groups?.findIndex((t) => t.team?._id === team?._id) > -1
                    }
                    onClick={() => {
                      if (selectedSlot) {
                        let new_groups = [...groups];
                        new_groups = new_groups.filter(
                          (t) => t.group !== selectedSlot
                        );
                        new_groups.push({ team: team, group: selectedSlot });
                        setGroups(new_groups);
                      }
                    }}
                  >
                    {team?.name}
                  </Team>
                );
              })}
          </Teams>
          <Teams>
            {JSON.parse(JSON.stringify(selectedSeason?.teams || []))
              ?.sort((a, b) => {
                if (a.name < b.name) {
                  return -1;
                }
                if (a.name > b.name) {
                  return 1;
                }
                return 0;
              })
              ?.slice(
                selectedSeason?.teams?.length / 2,
                selectedSeason?.teams?.length
              )
              ?.map((team) => {
                return (
                  <Team
                    fade={
                      groups?.findIndex((t) => t.team?._id === team?._id) > -1
                    }
                    onClick={() => {
                      if (selectedSlot) {
                        let new_groups = [...groups];
                        new_groups = new_groups.filter(
                          (t) => t.group !== selectedSlot
                        );
                        new_groups.push({ team: team, group: selectedSlot });
                        setGroups(new_groups);
                      }
                    }}
                  >
                    {team?.name}
                  </Team>
                );
              })}
          </Teams>
          <Group>
            <Title>Group A</Title>
            <GroupTeams>
              <GroupItem
                slot="group_1_slot_1"
                groups={groups}
                setGroups={setGroups}
                selectedSlot={selectedSlot}
                setSelectedSlot={setSelectedSlot}
              />
              <GroupItem
                slot="group_1_slot_2"
                groups={groups}
                setGroups={setGroups}
                selectedSlot={selectedSlot}
                setSelectedSlot={setSelectedSlot}
              />
              <GroupItem
                slot="group_1_slot_3"
                groups={groups}
                setGroups={setGroups}
                selectedSlot={selectedSlot}
                setSelectedSlot={setSelectedSlot}
              />
              <GroupItem
                slot="group_1_slot_4"
                groups={groups}
                setGroups={setGroups}
                selectedSlot={selectedSlot}
                setSelectedSlot={setSelectedSlot}
              />
            </GroupTeams>
          </Group>
          <Group>
            <Title>Group B</Title>
            <GroupTeams>
              <GroupItem
                slot="group_2_slot_1"
                groups={groups}
                setGroups={setGroups}
                selectedSlot={selectedSlot}
                setSelectedSlot={setSelectedSlot}
              />
              <GroupItem
                slot="group_2_slot_2"
                groups={groups}
                setGroups={setGroups}
                selectedSlot={selectedSlot}
                setSelectedSlot={setSelectedSlot}
              />
              <GroupItem
                slot="group_2_slot_3"
                groups={groups}
                setGroups={setGroups}
                selectedSlot={selectedSlot}
                setSelectedSlot={setSelectedSlot}
              />
              <GroupItem
                slot="group_2_slot_4"
                groups={groups}
                setGroups={setGroups}
                selectedSlot={selectedSlot}
                setSelectedSlot={setSelectedSlot}
              />
            </GroupTeams>
          </Group>
          <Group>
            <Title>Group C</Title>
            <GroupTeams>
              <GroupItem
                slot="group_3_slot_1"
                groups={groups}
                setGroups={setGroups}
                selectedSlot={selectedSlot}
                setSelectedSlot={setSelectedSlot}
              />
              <GroupItem
                slot="group_3_slot_2"
                groups={groups}
                setGroups={setGroups}
                selectedSlot={selectedSlot}
                setSelectedSlot={setSelectedSlot}
              />
              <GroupItem
                slot="group_3_slot_3"
                groups={groups}
                setGroups={setGroups}
                selectedSlot={selectedSlot}
                setSelectedSlot={setSelectedSlot}
              />
              <GroupItem
                slot="group_3_slot_4"
                groups={groups}
                setGroups={setGroups}
                selectedSlot={selectedSlot}
                setSelectedSlot={setSelectedSlot}
              />
            </GroupTeams>
          </Group>
          <Group>
            <Title>Group D</Title>
            <GroupTeams>
              <GroupItem
                slot="group_4_slot_1"
                groups={groups}
                setGroups={setGroups}
                selectedSlot={selectedSlot}
                setSelectedSlot={setSelectedSlot}
              />
              <GroupItem
                slot="group_4_slot_2"
                groups={groups}
                setGroups={setGroups}
                selectedSlot={selectedSlot}
                setSelectedSlot={setSelectedSlot}
              />
              <GroupItem
                slot="group_4_slot_3"
                groups={groups}
                setGroups={setGroups}
                selectedSlot={selectedSlot}
                setSelectedSlot={setSelectedSlot}
              />
              <GroupItem
                slot="group_4_slot_4"
                groups={groups}
                setGroups={setGroups}
                selectedSlot={selectedSlot}
                setSelectedSlot={setSelectedSlot}
              />
            </GroupTeams>
          </Group>
        </Row>
        <Row>Custom</Row>
        <Row>
          <Teams>
            {customTeams?.map((team) => {
              return (
                <Team
                  fade={
                    groups?.findIndex((t) => t.team?._id === team?._id) > -1
                  }
                  onClick={() => {
                    if (selectedSlot) {
                      let new_groups = [...groups];
                      new_groups = new_groups.filter(
                        (t) => t.group !== selectedSlot
                      );
                      new_groups.push({ team: team, group: selectedSlot });
                      setGroups(new_groups);
                    }
                  }}
                >
                  {team?.name}
                </Team>
              );
            })}
          </Teams>
        </Row>
        <Row style={{ marginTop: "40px" }}>
          <Input
            placeholder="Custom team name"
            value={newCustomTeam}
            onChange={(e) => {
              setNewCustomTeam(e.currentTarget.value);
            }}
          />
          <Button
            style={{ marginLeft: "20px" }}
            onClick={() => {
              let new_teams = [...customTeams];
              new_teams.push({
                _id: Date.now() + "_" + newCustomTeam,
                name: newCustomTeam,
              });
              setCustomTeams(new_teams);
              setNewCustomTeam("");
            }}
          >
            + Add Team
          </Button>
        </Row>
        <Row style={{ marginTop: "100px" }}>
          <Button
            red
            onClick={() => {
              setGroups([]);
            }}
          >
            RESET DATA
          </Button>
          <Button
            style={{ marginLeft: "40px" }}
            red
            onClick={() => {
              setCustomTeams([]);
            }}
          >
            CLEAR CUSTOM TEAMS
          </Button>
        </Row>
      </Content>
      <RightBar>
        <RightSideBar
          groups={groups}
          competition={selectedCompetition}
          previewGraphic={previewGraphic}
          graphicInPreview={graphicInPreview}
          animateGraphic={animateGraphic}
          animateOffGraphic={animateOffGraphic}
          updateGraphic={updateGraphic}
          clearPreview={clearPVW}
        />
      </RightBar>
    </Main>
  );
}

function GroupItem({ slot, groups, selectedSlot, setSelectedSlot, setGroups }) {
  return (
    <Team
      fade={!groups?.find((g) => g.group === slot)}
      selected={selectedSlot === slot}
      onClick={() => {
        setSelectedSlot(slot);
      }}
    >
      {groups?.find((g) => g.group === slot)?.team?.name ||
        "Team " + slot?.split("_")[3]}
      {groups?.find((g) => g.group === slot) && selectedSlot === slot && (
        <Button
          style={{ marginLeft: "auto", marginRight: "5px" }}
          red
          onClick={() => {
            setGroups(groups?.filter((g) => g.group !== slot));
          }}
        >
          Remove
        </Button>
      )}
    </Team>
  );
}
export default Draw;
