import React from "react";
import Select from "react-select";
import styled from "styled-components";

const SelectContainer = styled(Select)`
  width: 100%;
`;

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: "#253042",
    color: "white",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: "#253042",
    color: "white",
  }),
  control: (provided) => ({
    ...provided,
    backgroundColor: "#253042",
    height: "40px",
    color: "white",
    border: "1px solid #253042",
  }),
  container: (provided) => ({
    ...provided,
    width: "100%",
    // marginLeft: '10px'
    // , marginRight: '10px'
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";
    return { ...provided, opacity, transition, color: "white" };
  },
};

export default function SelectUI({ options, value, onChange, placeholder }) {
  return (
    <SelectContainer
      setSize={true}
      value={value}
      options={options}
      onChange={(e) => {
        onChange(e.value);
      }}
      placeholder={placeholder}
      styles={customStyles}
    />
  );
}
