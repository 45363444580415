import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import {
  GiAmericanFootballBall,
  GiRugbyConversion,
  GiSoccerBall,
} from "react-icons/gi";
import { IoIosGitCompare, IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { GraphicsContext } from "../../contexts/GraphicsContext";
import moment from "moment";
import { ScrollBar } from "../../Theme/Hyper";
import { DataContext } from "../../contexts/DataContext";
import { APIContext } from "../../contexts/APIContext";
import { UIContext } from "../../contexts/UIContext";
const Main = styled(ScrollBar)`
  display: flex;
  width: 40%;
  flex-direction: column;
  overflow-y: auto;
  height: 85vh;
`;
const Team = styled.div`
  /* height: 90%; */
`;
const Row = styled.div`
  display: flex;
  width: 100%;
  min-height: ${(props) => (!props.noHover ? "39px" : "")};
  padding-right: 0.5em;
  font-size: 1em;
  font-weight: 100;
  align-items: center;
  box-sizing: border-box;
  opacity: ${(props) => (props.sub ? "0.5" : "1")};
  background-color: ${(props) =>
    props.fade ? "#181d25" : props.selected ? "#db0a41" : "#202731"};
  border-bottom: 2px solid #181d25;
  cursor: ${(props) => (props.clickable ? "pointer" : "")};
  :hover {
    ${(props) => !props.noHover && !props.selected && { color: "#d11e42" }}
  }
`;
const ShirtNumber = styled.div`
  display: flex;
  justify-content: center;
  min-width: 40px;
  /* border-right: 1px solid #d6d6d6; */
  padding-left: 0.5em;
`;

const Name = styled.div`
  /* min-width: 300px; */
  display: flex;
  padding: 0.5em;
  width: 100%;
  color: ${(props) =>
    props.fade ? "#181d25" : props.selected ? "#fff" : "#fff"};

  background-color: ${(props) =>
    props.fade ? "#181d25" : props.selected ? "#db0a41" : "#202731"};
  :hover {
    color: ${(props) =>
    props.fade ? "#181d25" : props.selected ? "#fff" : "#d11e42"};
  }
`;
const FirstName = styled.div`
  display: flex;
  /* min-width: 200px; */
  /* border-right: 1px solid #d6d6d6; */
  padding-left: 0.5em;
`;
const LastName = styled.div`
  font-weight: 500;
  display: flex;
  /* min-width: 200px; */
  /* border-right: 1px solid #d6d6d6; */
  padding-left: ${(props) => (props.padLeft ? "0.5em" : "")};
`;
const Position = styled.div`
  display: flex;
  min-width: 150px;
  padding-left: 0.5em;
`;
const Sub = styled.div`
  display: flex;
  justify-content: center;
  min-width: 27px;
`;
const Captain = styled.div`
  display: flex;
  justify-content: center;
  min-width: 27px;
`;
const Checkbox = styled.input`
  width: 20px;
  height: 20px;
`;
const Right = styled.div`
  margin-left: auto;
  display: flex;
  justify-content: space-evenly;
`;
const YellowCard = styled.div`
  width: 18px;
  height: 25px;
  background-color: #fffe00;
  border-radius: 2px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
`;
const RedCard = styled.div`
  width: 18px;
  height: 25px;
  background-color: #ff0034;
  border-radius: 2px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
`;

const BlackCard = styled.div`
  width: 18px;
  height: 25px;
  background-color: #000;
  border-radius: 2px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
`;

const Cards = styled.div`
  /* margin-left: auto; */
  display: flex;
`;

const Card = styled.div`
  margin-right: 10px;
  cursor: pointer;
  width: 21px;
  height: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  opacity: ${(props) => (props.selected ? "1" : "0.1")};
  :hover {
    opacity: 1;
  }
`;

const Goals = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  margin-right: 10px;
`;
const Goal = styled.div`
  display: flex;
  align-items: center;
  margin-left: 0.5em;
  color: ${(props) => (props.red ? "red" : "white")};
  border-radius: 2px;
  padding: 2px;
  box-sizing: border-box;
  height: 21px;
  white-space: nowrap;
  :hover {
    background-color: #d11e42;
  }
`;
const SubIcon = styled.div`
  display: flex;
  align-items: center;
  margin-left: 0.5em;
  color: ${(props) => (props.on ? "#0adb93" : props.off ? "#db0a41" : "white")};
  border-radius: 2px;
  padding: 2px;
  box-sizing: border-box;
  height: 21px;
  opacity: ${(props) => (props.on ? "1" : props.off ? "1" : " 0.2")};
  min-width: 30px;
  justify-content: flex-end;
  :hover {
    /* background-color: #d11e42; */

    opacity: 1;
  }
`;
const FootballIcon = styled(GiSoccerBall)`
  margin-right: 2px;
`;
const ConversionIcon = styled(GiRugbyConversion)`
  margin-right: 2px;
`;
const BeenSubbed = styled.div`
  display: flex;
  justify-content: center;
  min-width: 20px;
`;
const Off = styled(IoIosArrowDown)`
  color: #db0a41;
`;
const On = styled(IoIosArrowUp)`
  color: #0adb93;
`;
const Button = styled.div`
  display: flex;
  align-items: center;
  background-color: #202731;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 0.5em;
  margin-bottom: 1em;

  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-grow: 1;
  padding: 0.5em;
  :hover {
    background-color: #db0a41;
  }
`;
const Popup = styled.div`
  background-color: ${(props) => props.theme.panelColour};
  border-radius: 4px;
  width: 200px;
  box-shadow: 0px 4px 12px -20px rgba(0, 0, 0, 0.2),
    0px 8px 20px 2px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
  font-size: 0.8em;
  overflow: hidden;
`;

const PopupItem = styled.div`
  padding: 0.5em;
  :hover {
    background-color: #d11e42;
  }
  /* border-bottom: 1px solid grey; */
`;
const PopupGroup = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid black;
`;

export default function Lineup({
  team,
  players,
  lineup,
  scorers,
  selectPlayer,
  selectedPlayer,
  game,
  updateGoal,
  removeGoal,
  setTeamManager,
  setTeamSelector1,
  setTeamSelector2,
  setTeamSelector3,
}) {
  const { previewGraphic } = useContext(GraphicsContext);
  const { update, sky, countries, seasonStats } = useContext(DataContext);
  const { setEditPlayer } = useContext(UIContext);
  const { updateGame, getSquad } = useContext(APIContext);
  const [manager, setManager] = useState();
  const [contextMenuItem, setContextMenuItem] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  function handleContextMenu(e, player) {
    setContextMenuItem({
      item: player,
      mouseX: e.clientX,
      mouseY: e.clientY,
      showContext: true,
    });
    e.preventDefault();
    window.addEventListener("click", handleClick);
  }

  function handleClick() {
    setContextMenuItem({
      ...contextMenuItem,
      showContext: false,
    });
    window.removeEventListener("click", handleClick);
  }

  useEffect(() => {
    getSquad({ teamId: team?._id, seasonId: game.season?._id }).then((data) => {
      setManager(data.find((s) => s.type === "coach"));
      setTeamManager(data.find((s) => s.type === "coach"));
      setTeamSelector1(data.find((s) => s.type === "selector1"));
      setTeamSelector2(data.find((s) => s.type === "selector2"));
      setTeamSelector3(data.find((s) => s.type === "selector3"));
    });
  }, [team]);

  let team_type = team._id === game.home_team._id ? "home" : "away";

  return (
    <Main>
      <Team>
        {new Array(lineup?.length < 26 ? 26 : lineup?.length)
          .fill(1)
          .map((item, index) => {
            let l = lineup?.find((l) => l.order === index + 1);

            let player = {
              _id: l?.squad?._id,
              ...l?.squad?.player,
              order: l?.order,
              shirt_number:
                l?.squad?.shirt_number || l?.squad?.opta_shirt_number,
              squad: l?.squad,
              yellow_card: l?.yellow_card,
              dbl_yellow_card: l?.dbl_yellow_card,
              red_card: l?.red_card,
              black_card: l?.black_card,
              sub_order: l?.sub_order,
              subbed: l?.subbed,
              captain: l?.captain,
            };

            return (
              <>
                {index === 15 && (
                  <Row fade noHover>
                    <ShirtNumber></ShirtNumber>
                    <Name fade>
                      <FirstName></FirstName>
                    </Name>
                  </Row>
                )}
                <Row
                  onDrop={() => {
                    let newHomeScorers = [...game.home_scorers];
                    let newAwayScorers = [...game.away_scorers];
                    let index = newHomeScorers.findIndex((g) => {
                      return (
                        g.time === window.goalDrag.time &&
                        g.player === window.goalDrag.player
                      );
                    });
                    if (index > -1) {
                      newHomeScorers[index] = {
                        ...newHomeScorers[index],
                        player: player.squad._id,
                        own_goal: team_type !== "home",
                      };
                    } else if (index === -1) {
                      index = newAwayScorers.findIndex((g) => {
                        return (
                          g.time === window.goalDrag.time &&
                          g.player === window.goalDrag.player
                        );
                      });
                      newAwayScorers[index] = {
                        ...newAwayScorers[index],
                        player: player.squad._id,
                        own_goal: team_type !== "away",
                      };
                    }

                    updateGame({
                      id: game._id,
                      home_scorers: newHomeScorers,
                      away_scorers: newAwayScorers,
                    });
                  }}
                  onDragOver={(ev) => {
                    ev.preventDefault();
                  }}
                  clickable
                  sub={!player?.squad || player?.order > 15}
                >
                  <ShirtNumber>{player?.shirt_number}</ShirtNumber>

                  <BeenSubbed>
                    {player?.subbed && player?.order <= 15 && <On />}
                    {player?.subbed && player?.order > 15 && <Off />}
                  </BeenSubbed>
                  <Name
                    selected={
                      selectedPlayer?._id && selectedPlayer?._id === player._id
                    }
                    onContextMenu={(e) => {
                      handleContextMenu(e, player);
                    }}
                    onClick={() => {
                      if (
                        !selectedPlayer ||
                        selectedPlayer._id !== player._id
                      ) {
                        debugger;
                        selectPlayer(player);
                      } else {
                        selectPlayer(null);
                      }
                    }}
                  >
                    <FirstName>{player?.first_name}</FirstName>
                    <LastName padLeft={player?.first_name}>
                      {player?.last_name} {player && player.captain && "(C)"}
                      <span
                        style={{
                          fontSize: ".7em",
                          fontWeight: "100",
                          alignSelf: "flex-end",
                          marginLeft: ".5em",
                        }}
                      >
                        {player &&
                          player.nationality &&
                          player.nationality.code}
                      </span>
                    </LastName>
                  </Name>
                  <Goals>
                    {scorers
                      ?.filter((g) => {
                        return (
                          g.player &&
                          player.squad &&
                          g?.player === player?.squad?._id &&
                          g?.type === "goal"
                        );
                      })
                      ?.map((goal) => {
                        let time = moment.duration(goal.time);
                        let minutes = time.minutes() + time.hours() * 60;
                        let goalTime = "";
                        // minutes += 1;
                        if (
                          goal.period === 2 &&
                          minutes > (game?.config?.half_duration || 35) * 2
                        ) {
                          goalTime =
                            (game?.config?.half_duration || 35) * 2 +
                            "+" +
                            (minutes - (game?.config?.half_duration || 35) * 2);
                        } else if (
                          goal.period === 1 &&
                          minutes > (game?.config?.half_duration || 35)
                        ) {
                          goalTime =
                            "35+" +
                            (minutes - (game?.config?.half_duration || 35));
                        } else {
                          goalTime = minutes;
                        }
                        return (
                          <Goal
                            draggable="true"
                            onDragStart={() => {
                              window.goalDrag = { ...goal, team: team_type };
                            }}
                            red={goal?.own_goal}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            onContextMenu={(e) => {
                              e.preventDefault();
                            }}
                            onMouseDown={(e) => {
                              switch (e.button) {
                                case 0:
                                  // previewGraphic("PlayerScored", {
                                  //   goal: goal,
                                  //   player: player,
                                  //   time: time.minutes() + time.hours() * 60,
                                  //   seconds: time.seconds(),
                                  //   team: team,
                                  //   game,
                                  // });
                                  break;
                                case 2:
                                  removeGoal(goal);
                                  break;
                                default:
                              }
                            }}
                          >
                            <FootballIcon />
                            {goalTime} {goal.penalty && "P"}
                          </Goal>
                        );
                      })}
                    {scorers
                      ?.filter((g) => {
                        return (
                          g.player &&
                          player.squad &&
                          g?.player === player?.squad?._id &&
                          g?.type === "point"
                        );
                      })
                      ?.map((goal) => {
                        let time = moment.duration(goal.time);
                        let minutes = time.minutes() + time.hours() * 60;
                        let goalTime = "";
                        // minutes += 1;
                        if (
                          goal.period === 2 &&
                          minutes > (game?.config?.half_duration || 35) * 2
                        ) {
                          goalTime =
                            (game?.config?.half_duration || 35) * 2 +
                            "+" +
                            (minutes - (game?.config?.half_duration || 35) * 2);
                        } else if (
                          goal.period === 1 &&
                          minutes > (game?.config?.half_duration || 35)
                        ) {
                          goalTime =
                            "35+" +
                            (minutes - (game?.config?.half_duration || 35));
                        } else {
                          goalTime = minutes;
                        }
                        return (
                          <Goal
                            draggable="true"
                            onDragStart={() => {
                              window.goalDrag = { ...goal, team: team_type };
                            }}
                            red={goal?.own_goal}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            onContextMenu={(e) => {
                              e.preventDefault();
                            }}
                            onMouseDown={(e) => {
                              switch (e.button) {
                                case 0:
                                  // previewGraphic("PlayerScored", {
                                  //   goal: goal,
                                  //   player: player,
                                  //   time: time.minutes() + time.hours() * 60,
                                  //   seconds: time.seconds(),
                                  //   team: team,
                                  //   game,
                                  // });
                                  break;
                                case 2:
                                  removeGoal(goal);
                                  break;
                                default:
                              }
                            }}
                          >
                            <ConversionIcon />
                            {goalTime} {goal.penalty && "P"}
                          </Goal>
                        );
                      })}
                  </Goals>
                  {player?.squad && (
                    <Cards>
                      <Card
                        selected={player && player.yellow_card}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        onContextMenu={(e) => {
                          e.preventDefault();
                        }}
                        onMouseDown={(e) => {
                          e.stopPropagation();

                          let newLineup = [...lineup];

                          newLineup = newLineup.map((item) => {
                            return {
                              ...item,
                              squad: item.squad._id,
                            };
                          });

                          switch (e.button) {
                            case 0:
                              newLineup.find(
                                (l) => l.squad === player.squad._id
                              ).yellow_card = true;
                              break;
                            case 2:
                              newLineup.find(
                                (l) => l.squad === player.squad._id
                              ).yellow_card = false;
                              break;
                            default:
                          }

                          if (game.home_team._id === team._id) {
                            newLineup = {
                              home: newLineup,
                              away: game.lineup.away,
                            };
                          } else if (game.away_team._id === team._id) {
                            newLineup = {
                              away: newLineup,
                              home: game.lineup.home,
                            };
                          }

                          previewGraphic("Card", {
                            player,
                            cardType: 1,
                            team: team,
                            game,
                          });

                          updateGame({
                            id: game._id,
                            lineup: newLineup,
                          });
                        }}
                      >
                        <YellowCard />
                      </Card>
                      <Card
                        selected={player && player.dbl_yellow_card}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        onContextMenu={(e) => {
                          e.preventDefault();
                        }}
                        onMouseDown={(e) => {
                          e.stopPropagation();

                          let newLineup = [...lineup];

                          newLineup = newLineup.map((item) => {
                            return {
                              ...item,
                              squad: item.squad._id,
                            };
                          });

                          switch (e.button) {
                            case 0:
                              newLineup.find(
                                (l) => l.squad === player.squad._id
                              ).dbl_yellow_card = true;
                              break;
                            case 2:
                              newLineup.find(
                                (l) => l.squad === player.squad._id
                              ).dbl_yellow_card = false;
                              break;
                            default:
                          }

                          if (game.home_team._id === team._id) {
                            newLineup = {
                              home: newLineup,
                              away: game.lineup.away,
                            };
                          } else if (game.away_team._id === team._id) {
                            newLineup = {
                              away: newLineup,
                              home: game.lineup.home,
                            };
                          }
                          previewGraphic("Card", {
                            player,
                            cardType: 2,
                            team: team,
                            game,
                          });
                          updateGame({
                            id: game._id,
                            lineup: newLineup,
                          });
                        }}
                      >
                        <YellowCard />
                      </Card>
                      <Card
                        selected={player && player.black_card}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        onContextMenu={(e) => {
                          e.preventDefault();
                        }}
                        onMouseDown={(e) => {
                          e.stopPropagation();

                          let newLineup = [...lineup];

                          newLineup = newLineup.map((item) => {
                            return {
                              ...item,
                              squad: item.squad._id,
                            };
                          });
                          debugger;
                          switch (e.button) {
                            case 0:
                              newLineup.find(
                                (l) => l.squad === player.squad._id
                              ).black_card = true;
                              break;
                            case 2:
                              newLineup.find(
                                (l) => l.squad === player.squad._id
                              ).black_card = false;
                              break;
                            default:
                          }

                          if (game.home_team._id === team._id) {
                            newLineup = {
                              home: newLineup,
                              away: game.lineup.away,
                            };
                          } else if (game.away_team._id === team._id) {
                            newLineup = {
                              away: newLineup,
                              home: game.lineup.home,
                            };
                          }
                          previewGraphic("Card", {
                            player,
                            cardType: 4,
                            team: team,
                            game,
                          });
                          updateGame({
                            id: game._id,
                            lineup: newLineup,
                          });
                        }}
                      >
                        <BlackCard />
                      </Card>
                      <Card
                        selected={player && player.red_card}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        onContextMenu={(e) => {
                          e.preventDefault();
                        }}
                        onMouseDown={(e) => {
                          e.stopPropagation();

                          let newLineup = [...lineup];

                          newLineup = newLineup.map((item) => {
                            return {
                              ...item,
                              squad: item.squad._id,
                            };
                          });

                          switch (e.button) {
                            case 0:
                              newLineup.find(
                                (l) => l.squad === player.squad._id
                              ).red_card = true;
                              break;
                            case 2:
                              newLineup.find(
                                (l) => l.squad === player.squad._id
                              ).red_card = false;
                              break;
                            default:
                          }

                          if (game.home_team._id === team._id) {
                            newLineup = {
                              home: newLineup,
                              away: game.lineup.away,
                            };
                          } else if (game.away_team._id === team._id) {
                            newLineup = {
                              away: newLineup,
                              home: game.lineup.home,
                            };
                          }
                          previewGraphic("Card", {
                            player,
                            cardType: 3,
                            team: team,
                            game,
                          });
                          updateGame({
                            id: game._id,
                            lineup: newLineup,
                          });
                        }}
                      >
                        <RedCard />
                      </Card>
                    </Cards>
                  )}
                  {player?.squad && (
                    <>
                      <SubIcon
                        on={player?.sub_order === 1 && player?.order > 15}
                        off={player?.sub_order === 1 && player?.order <= 15}
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                        }}
                        onContextMenu={(e) => {
                          e.preventDefault();
                        }}
                        onMouseDown={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          let newLineup = [...lineup];

                          newLineup = newLineup.map((item) => {
                            return {
                              ...item,
                              squad: item.squad._id,
                            };
                          });

                          switch (e.button) {
                            case 0:
                              let off = player.order <= 15;

                              newLineup = newLineup.map((item) => {
                                if (off) {
                                  if (
                                    item.order <= 15 &&
                                    item.sub_order === 1
                                  ) {
                                    return {
                                      ...item,
                                      sub_order: null,
                                    };
                                  }
                                } else if (
                                  item.order > 15 &&
                                  item.sub_order === 1
                                ) {
                                  return {
                                    ...item,
                                    sub_order: null,
                                  };
                                }
                                return item;
                              });
                              newLineup.find(
                                (l) => l.squad === player.squad._id
                              ).sub_order = 1;

                              break;
                            case 2:
                              newLineup.find(
                                (l) => l.squad === player.squad._id
                              ).sub_order = null;
                              break;
                            default:
                              break;
                          }

                          if (game.home_team._id === team._id) {
                            newLineup = {
                              home: newLineup,
                              away: game.lineup.away,
                            };
                          } else if (game.away_team._id === team._id) {
                            newLineup = {
                              away: newLineup,
                              home: game.lineup.home,
                            };
                          }

                          updateGame({
                            id: game._id,
                            lineup: newLineup,
                          });
                        }}
                      >
                        <IoIosGitCompare></IoIosGitCompare>
                      </SubIcon>
                      <SubIcon
                        on={player?.sub_order === 2 && player?.order > 15}
                        off={player?.sub_order === 2 && player?.order <= 15}
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                        }}
                        onContextMenu={(e) => {
                          e.preventDefault();
                        }}
                        onMouseDown={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          let newLineup = [...lineup];

                          newLineup = newLineup.map((item) => {
                            return {
                              ...item,
                              squad: item.squad._id,
                            };
                          });

                          switch (e.button) {
                            case 0:
                              let off = player.order <= 15;

                              newLineup = newLineup.map((item) => {
                                if (off) {
                                  if (
                                    item.order <= 15 &&
                                    item.sub_order === 2
                                  ) {
                                    return {
                                      ...item,
                                      sub_order: null,
                                    };
                                  }
                                } else if (
                                  item.order > 15 &&
                                  item.sub_order === 2
                                ) {
                                  return {
                                    ...item,
                                    sub_order: null,
                                  };
                                }
                                return item;
                              });
                              newLineup.find(
                                (l) => l.squad === player.squad._id
                              ).sub_order = 2;

                              break;
                            case 2:
                              newLineup.find(
                                (l) => l.squad === player.squad._id
                              ).sub_order = null;
                              break;
                            default:
                              break;
                          }

                          if (game.home_team._id === team._id) {
                            newLineup = {
                              home: newLineup,
                              away: game.lineup.away,
                            };
                          } else if (game.away_team._id === team._id) {
                            newLineup = {
                              away: newLineup,
                              home: game.lineup.home,
                            };
                          }

                          updateGame({
                            id: game._id,
                            lineup: newLineup,
                          });
                        }}
                      >
                        <IoIosGitCompare></IoIosGitCompare>
                      </SubIcon>
                      <SubIcon
                        on={player?.sub_order === 3 && player?.order > 15}
                        off={player?.sub_order === 3 && player?.order <= 15}
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                        }}
                        onContextMenu={(e) => {
                          e.preventDefault();
                        }}
                        onMouseDown={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          let newLineup = [...lineup];

                          newLineup = newLineup.map((item) => {
                            return {
                              ...item,
                              squad: item.squad._id,
                            };
                          });

                          switch (e.button) {
                            case 0:
                              let off = player.order <= 15;

                              newLineup = newLineup.map((item) => {
                                if (off) {
                                  if (
                                    item.order <= 15 &&
                                    item.sub_order === 3
                                  ) {
                                    return {
                                      ...item,
                                      sub_order: null,
                                    };
                                  }
                                } else if (
                                  item.order > 15 &&
                                  item.sub_order === 3
                                ) {
                                  return {
                                    ...item,
                                    sub_order: null,
                                  };
                                }
                                return item;
                              });
                              newLineup.find(
                                (l) => l.squad === player.squad._id
                              ).sub_order = 3;

                              break;
                            case 2:
                              newLineup.find(
                                (l) => l.squad === player.squad._id
                              ).sub_order = null;
                              break;
                            default:
                              break;
                          }

                          if (game.home_team._id === team._id) {
                            newLineup = {
                              home: newLineup,
                              away: game.lineup.away,
                            };
                          } else if (game.away_team._id === team._id) {
                            newLineup = {
                              away: newLineup,
                              home: game.lineup.home,
                            };
                          }

                          updateGame({
                            id: game._id,
                            lineup: newLineup,
                          });
                        }}
                      >
                        <IoIosGitCompare></IoIosGitCompare>
                      </SubIcon>
                    </>
                  )}
                </Row>
              </>
            );
          })}
      </Team>
      <Row fade noHover>
        <ShirtNumber></ShirtNumber>
        <Name fade>
          <FirstName></FirstName>
        </Name>
      </Row>
      {manager && (
        <Manager
          player={manager}
          selectedPlayer={selectedPlayer}
          selectPlayer={(player) => {
            selectPlayer(player);
          }}
          countries={countries}
          game={game}
          update={update}
          sky={sky}
          team={team}
          team_type={team_type}
          lineup={lineup}
        />
      )}
    </Main>
  );
}

function Manager({
  player,
  selectedPlayer,
  selectPlayer,
  previewGraphic,
  lineup,
  game,
  update,
  countries,
  sky,
  team,
  team_type,
}) {
  return (
    <Row
      clickable
      onClick={() => {
        if (!selectedPlayer || selectedPlayer._id !== player.player?._id) {
          selectPlayer({ ...player, ...player?.player });
        } else {
          selectPlayer(null);
        }
      }}
    >
      <ShirtNumber> </ShirtNumber>
      <BeenSubbed></BeenSubbed>
      <Name
        selected={selectedPlayer && selectedPlayer._id === player.player?._id}
      >
        <FirstName>{player.player?.first_name}</FirstName>
        <LastName padLeft={player.player?.first_name}>
          {player.player?.last_name} ({player.player?.title})
        </LastName>
      </Name>
      <Right></Right>
    </Row>
  );
}
