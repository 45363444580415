export class FFDraw {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "FFDraw";
    this.scene = "FF_Draw";
    this.data = [];
  }

  preview({ options }) {
    this.onAir = false;
    this.data = [];
    this.data.push({
      name: "Title",
      value: {
        text: options?.competition?.name || "",
      },
    });
    for (let i = 1; i <= 4; i++) {
      this.data.push({
        name: "GRP" + i + "Team_1",
        value: {
          text:
            options?.groups?.find((g) => g.group === "group_" + i + "_slot_1")
              ?.team?.name || "",
        },
      });
      this.data.push({
        name: "GRP" + i + "Team_2",
        value: {
          text:
            options?.groups?.find((g) => g.group === "group_" + i + "_slot_2")
              ?.team?.name || "",
        },
      });
      this.data.push({
        name: "GRP" + i + "Team_3",
        value: {
          text:
            options?.groups?.find((g) => g.group === "group_" + i + "_slot_3")
              ?.team?.name || "",
        },
      });
      this.data.push({
        name: "GRP" + i + "Team_4",
        value: {
          text:
            options?.groups?.find((g) => g.group === "group_" + i + "_slot_4")
              ?.team?.name || "",
        },
      });
    }

    window.control_ws.send(
      JSON.stringify({
        action: "preview",
        group: localStorage.getItem("user-id"),
        user: {
          id: window.id,
        },
        engine:
          window.engines?.find((e) => e.key === window.selectedEngine)?.key ||
          window.engines?.[0]?.key,
        data: {
          scene: this.scene,
          frame: 500,
          timeline: "IN",
          data: this.data,
        },
        from_control: true,
      })
    );
  }

  update({ options }) {
    this.data.push({
      name: "Title",
      value: {
        text: options?.competition?.name || "",
      },
    });
    for (let i = 1; i <= 4; i++) {
      this.data.push({
        name: "GRP" + i + "Team_1",
        value: {
          text:
            options?.groups?.find((g) => g.group === "group_" + i + "_slot_1")
              ?.team?.name || "",
        },
      });
      this.data.push({
        name: "GRP" + i + "Team_2",
        value: {
          text:
            options?.groups?.find((g) => g.group === "group_" + i + "_slot_2")
              ?.team?.name || "",
        },
      });
      this.data.push({
        name: "GRP" + i + "Team_3",
        value: {
          text:
            options?.groups?.find((g) => g.group === "group_" + i + "_slot_3")
              ?.team?.name || "",
        },
      });
      this.data.push({
        name: "GRP" + i + "Team_4",
        value: {
          text:
            options?.groups?.find((g) => g.group === "group_" + i + "_slot_4")
              ?.team?.name || "",
        },
      });
    }

    window.control_ws.send(
      JSON.stringify({
        action: "update",
        group: localStorage.getItem("user-id"),
        user: {
          id: window.id,
        },
        engine:
          window.engines?.find((e) => e.key === window.selectedEngine)?.key ||
          window.engines?.[0]?.key,
        data: {
          scene: this.scene,
          frame: 500,
          timeline: "IN",
          data: this.data,
        },
        from_control: true,
      })
    );
  }
  animate({ options }) {
    this.onAir = true;

    window.control_ws.send(
      JSON.stringify({
        action: "animate",
        group: localStorage.getItem("user-id"),
        user: {
          id: window.id,
        },
        engine:
          window.engines?.find((e) => e.key === window.selectedEngine)?.key ||
          window.engines?.[0]?.key,
        data: {
          scene: this.scene,
          frame: 500,
          timeline: "IN",
          data: this.data,
        },
        from_control: true,
      })
    );
  }
  animateOff({ options }) {
    this.onAir = false;
    window.control_ws.send(
      JSON.stringify({
        action: "animate",
        group: localStorage.getItem("user-id"),
        user: {
          id: window.id,
        },
        engine:
          window.engines?.find((e) => e.key === window.selectedEngine)?.key ||
          window.engines?.[0]?.key,
        data: {
          scene: this.scene,
          frame: 500,
          timeline: "OUT",
          data: this.data,
        },
        from_control: true,
      })
    );
  }
}
